import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Jumbotron }  from 'react-bootstrap';


function Contact(){
    return(
        <div className="container mt-2 text-white" id="contact">
        <h1>Contact </h1>
        <p>Sherinengineer@yahoo.com</p>
        
        </div>
    );
}

export default Contact;