import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {Container,Row,Col} from 'react-bootstrap'
import Navba from './navbar';
import Footer from './footer';
import About from './about';
import Works from './works';
import Contact from './contact';


function App() {
  return (
      <Router>
        <div className="App">

          <Navba/>
          <Switch>
          <Route path="/" exact component ={Home}/>
          <Route path="/about" component ={About}/>
          <Route path="/works" component ={Works}/>
          <Route path="/contact" component ={Contact}/>
          </Switch>
           <About/>
           <Works/>
           <Contact/>
          <Footer/>
        </div>
      </Router>
        

  );
}



const Home = () =>(
  <div >
    <Container  >
      <Col style={{top:'40%',left:'0%',position:'absolute' }}>  
        <h1 className="hero-text text-center text-white">Welcome to sherin Portfolio</h1>
        <p className="text-center text-white">This is a simple web portfolio to show my recent works and a brief job profile.</p>
        <div className="clear-fix"></div>
        </Col>
      </Container>
      
  
  
    
    </div>
);




export default App;
