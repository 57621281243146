import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Jumbotron, Button, Container }  from 'react-bootstrap';


function About(){
    return(
        
        <Container id="about" className="container mt-2" >
        <h1 className="text-white ">About </h1>
        <p className="text-white"> I worked as a Digital marketing coordinator role. My role includes web UI designing, PPC, Reporting, leading Digital marketing activities for MNC clients. I have done google ads, and Facebook ads which contain monthly reporting to clients.</p>
        
        </Container>
    );
}

export default About;