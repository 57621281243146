import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {  Container ,Row, Col}  from 'react-bootstrap';



function Footer(){

    const footerStyle={
        marginTop:'20px',
        padding:'25px',
        backgroundColor: 'red',
        backgroundImage: 'linear-gradient(to right, rgba(234, 239, 240, 0.69), rgb(89, 112, 238))',
    };

    return(
        <footer style={footerStyle}>
        <Container>
        <Row>
          <Col xs={12} sm={8}><h2>About </h2>
          <p>This is a simple Portfolio, made by sherin to give extra attention to his works and achievements, content or website information are genuine. I choose some images owned by respective owners (Unsplash).</p>
          </Col>
         
          <Col xs={12} sm={4}><h2>Contact</h2>
          <p>sherinengineer@yahoo.com</p>
          <p>+919400280111</p>
          
          </Col>
        </Row>
       
      </Container>
      </footer>
    );
}

export default Footer;