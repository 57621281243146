import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Jumbotron, Button, Card, CardGroup}  from 'react-bootstrap';
import realestate from './Images/realestate.JPG';
import reactjs from './Images/reactjs.JPG';
import wordpress from './Images/wordpress.JPG';
import cycling from './Images/cycling.JPG';
import coffee from './Images/coffee.JPG';
import buildingconst from './Images/buildingconstruction.JPG';
import technology from './Images/technology.JPG';
import jewllery from './Images/jewllerys.JPG';
import shopify from './Images/shopify.jpg';
import uiuxphoto from './Images/uiuxphoto.jpg';
function Works(){
    return(
      <>
        <div className="container mt-2 " id="works">
        <h1 className="text-white">My Recent Works</h1>
        <p className="text-white">This section shows my recent projects i did during 2020-2021.</p>
            <CardGroup className="mb-4">
  <Card>
    <Card.Img variant="top" src={realestate} style={{height:'250px'}} />
    <Card.Body>
      <Card.Title>BeyondConceptdw</Card.Title>
      <Card.Text>
        This website is developed for a releastate comapany and it is sucessfully working on a live domain. <br/><Button target="_blank" href="https://beyondconceptdw.com">View</Button>
      </Card.Text>
    </Card.Body>
   
  </Card>
  <Card>
    <Card.Img variant="top" src={reactjs} style={{height:'250px'}}/>
    <Card.Body>
      <Card.Title>React Js Project</Card.Title>
      <Card.Text>
      This is a react js website I have done recently to improve my UI development knowledge <br/><Button target="_blank" href="http://sherinc.in/reactwebsite">View</Button>
      </Card.Text>
    </Card.Body>
    
  </Card>

  <Card>
    <Card.Img variant="top" src={buildingconst} style={{height:'250px'}}/>
    <Card.Body>
      <Card.Title>Realesate builders Dubai</Card.Title>
      <Card.Text>
      This is a web project I made for a building contracting company in Dubai <br/> <Button target ="_blank" href=" http://www.belresheed.ae/" >View</Button>
      </Card.Text>
    </Card.Body>
    
  </Card>
  
</CardGroup>

<CardGroup className="mb-4">
<Card>
    <Card.Img variant="top" src={coffee} style={{height:'250px'}}/>
    <Card.Body>
      <Card.Title>Roast speciality - Dubai</Card.Title>
      <Card.Text>
      This is an attractive web project I did for a specialty coffee shop in Dubai, Marina <br/> <Button target ="_blank" href=" http://www.roastdubai.com/" >View</Button>
      </Card.Text>
    </Card.Body>
    
  </Card>
  <Card>
    <Card.Img variant="top" src={ cycling} style={{height:'250px'}}/>
    <Card.Body>
      <Card.Title>Zad Foodstores Dubai</Card.Title>
      <Card.Text>
      This is an attractive web project I made for a food store with a coffee shop and that place is located near a cycling track in Dubai <br/> <Button target ="_blank" href=" http://www.zadfoodstores.com/" >View</Button>
      </Card.Text>
    </Card.Body>
    
  </Card>
  <Card>
    <Card.Img variant="top" src={technology} style={{height:'250px'}}/>
    <Card.Body>
      <Card.Title>AwebX </Card.Title>
      <Card.Text>
      This is a startup company web project and it is currently Works on progress <br/> <Button target ="_blank" href=" http://www.awebx.net/" >View</Button>
      </Card.Text>
    </Card.Body>
    
  </Card>
  <Card>
    <Card.Img variant="top" src={shopify} style={{height:'250px'}}/>
    <Card.Body>
      <Card.Title>Shopify website kanyasi </Card.Title>
      <Card.Text>
      This is a shopify ecommerce web project  for  a furniture shope and it is currently working in progress <br/> <Button target ="_blank" href=" https://kanyasi.myshopify.com/" >View</Button>
      </Card.Text>
    </Card.Body>
    
  </Card>
  
</CardGroup>



</div>


<div className="container">
<h1 className="text-center text-white  mb-3">My Recent Works in ui ux</h1>
<CardGroup>
<Card>
    <Card.Img variant="top" src={jewllery} style={{height:'450px', objectFit:'cover'}} />
    <Card.Body>
      <Card.Title >Kanas Jewllery</Card.Title>
      <Card.Text>
        This website is designed for a Jewllery shop (concept) and it is sucessfully completed using figma (desktop version) .
         <br/><Button target="_blank" href="https://www.figma.com/file/IwyoBodV6oZt9qrMc4CtPf/Untitled?node-id=0%3A1">View</Button>
      </Card.Text>
    </Card.Body>
   
  </Card>
  <Card>
    <Card.Img variant="top" src={uiuxphoto} style={{height:'450px', objectFit:'cover'}} />
    <Card.Body>
      <Card.Title >Workbench</Card.Title>
      <Card.Text>
        This website is designed for a workbench cms and it is sucessfully completed using adobe xd (desktop version) .
         <br/><Button target="_blank" href="https://www.sherinc.in/Designs/Website.pdf">View</Button>
      </Card.Text>
    </Card.Body>
   
  </Card>
</CardGroup>

</div>
    </>    

    );
}

export default Works;