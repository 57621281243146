import React from 'react';
import portfolio from './Images/backgroundimage.JPG';
//import {Link, withRouter, NavLink} from 'react-router-dom';
import {HashLink as Link } from 'react-router-hash-link';
import {Nav, Navbar,Container} from 'react-bootstrap';
import logo from './logo.svg'
import NavbarToggle from 'react-bootstrap/esm/NavbarToggle';
import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse';

function Navba(){

    const headerImage={
  backgroundImage:'url('+portfolio+')',
  backgroundPosition:'center',
  backgroundRepeat:'no-repeat',
  backgroundSize:'cover',
  height:'550px',
  zIndex:'999',
  
 Width:'100%'
    }

    return(
        <Container id="home" fluid style={headerImage} >
        <Navbar  bg="dark" varient="" fixed="top" expand="sm" collapseOnSelect>
          <Navbar.Brand>
              <img src={logo} width="40px" height="40px"/> <span className="text-white">My Portifolio</span>
              </Navbar.Brand>  

              <Navbar.Toggle/>
                  <NavbarCollapse>
                  <Nav className="">
                  <Link  activeClassName="is-active" smooth  to='#home'>Home</Link>
                  <Link  activeClassName="is-active" smooth to='#about'>About</Link>
                  <Link  activeClassName="is-active" smooth to='#works'>Works</Link>
                  <Link  activeClassName="is-active" smooth to="#contact">Contact</Link>

              </Nav> 
                  </NavbarCollapse>
             
                       
        </Navbar>
        </Container>
    );
}
export default Navba;